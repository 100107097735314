/* eslint-disable */
import React from 'react';

import { Card, CardImg, CardTitle, CardText, CardBody, Button, Container, Row, Col } from "reactstrap";
import { EqualHeight, EqualHeightElement } from 'react-equal-height';
import {Link} from 'react-router-dom';

function HomeProduct(props) {

  return (
    <>
      <div className="section bg-light">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">SẢN PHẨM</h2>
            </Col>
          </Row>
          <Row>
            <EqualHeight>
              {props.categories.map(item => (
                <Col lg="4" key={`HomeProduct-${item.id}`}>
                  <Card>
                    <EqualHeightElement name="CardImage">
                      <CardImg
                        alt="Category Image"
                        src={item.image_url}
                        top
                        width="100%"
                        height="300px"
                      />
                    </EqualHeightElement>
                    <EqualHeightElement name="CardBody">
                      <CardBody>
                        <EqualHeightElement name="CardTitle">
                          <CardTitle
                            tag="h3"
                            className="font-weight-bold">
                            {item.name}
                          </CardTitle>
                        </EqualHeightElement>
                        <EqualHeightElement name="CardText">
                          <CardText>
                            {item.description}
                          </CardText>
                        </EqualHeightElement>
                        <EqualHeightElement name="CardButton">
                          <Button
                            className="btn-round"
                            color="primary"
                            tag={Link} to={`/product/${item.id}`} 
                            size="lg"                            
                          >
                            Xem thêm
                          </Button>
                        </EqualHeightElement>
                      </CardBody>
                    </EqualHeightElement>
                  </Card>
                </Col>
              ))}
            </EqualHeight>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default HomeProduct;
