/*eslint-disable*/
import React, { useState } from "react";

import { Category } from "./models";
import { PageContent } from "./models";
import { DataStore } from '@aws-amplify/datastore';
import { Hub } from "@aws-amplify/core";

import Loading from "./Loading.js";
import Content from "./Content.js";

function App() {

	const [isLoading, setIsLoading] = useState(true);
	const [categories, setCategories] = useState([]);
	const [aboutContent, setAboutContent] = useState([]);
	const [headerContent, setHeaderContent] = useState([]);
	const [footerContent, setFooterContent] = useState([]);
	const [contactContent, setContactContent] = useState([]);

	async function fetchCategories() {
		const data = await DataStore.query(Category);
		setCategories(data);
		console.log("fetchCategories")
	}

	async function fetchContents() {
		const contents = await DataStore.query(PageContent);
		for (const content of contents) {
			if (content.page_name === "about") {
				setAboutContent(content);
			} else if (content.page_name === "header") {
				setHeaderContent(content);
			} else if (content.page_name === "footer") {
				setFooterContent(content);
			} else if (content.page_name === "contact") {
				setContactContent(content);
			} else {
				console.log("Welcome to Emachinesvn!!!");
			}
		}
		console.log("fetchContents")
	}

	React.useEffect(() => {
		const fetchData = async () => {
			await fetchCategories();
			await fetchContents();
			setIsLoading(false);
		};

		const listener = Hub.listen("datastore", async hubData => {
			const { event, data } = hubData.payload;
			if (event === "ready") {
				console.log("ready")
				fetchData();
			}
		})

		DataStore.start();
		return () => {
			listener();		
		};		
	}, []);


	return (
		<>
			{isLoading ?
				<Loading />
				:
				<Content
					categories={categories}
					aboutContent={aboutContent}
					headerContent={headerContent}
					footerContent={footerContent}
					contactContent={contactContent}
				/>
			}
		</>
	);
}

export default App;