import './assets/css/App.css';

function Loading() {
  return (
    <div className="App">
      <header className="App-header">      
        <img src="logo.gif" className="logo-container" alt="loading" />
      </header>
    </div>
  );
}

export default Loading;
