/* eslint-disable */
import React from "react";
import { Modal, ModalBody, Button, Container } from "reactstrap";

import ProductDetailCarousel from "./ProductDetailCarousel";
import ProductDetailBody from "./ProductDetailBody";

function ProductDetailModal(props) {

    const [modal, setModal] = React.useState(false);

    return (
        <>
            <div>
                <Button
                    className="btn-round"
                    color="primary"
                    size="lg"
                    onClick={() => setModal(true)}
                >
                    Xem thêm
                </Button>
                <Modal isOpen={modal} toggle={() => setModal(false)}>
                    <div className="modal-header justify-content-center">
                        <button
                            className="close"
                            style={{ color: 'black' }}
                            type="button"
                            onClick={() => setModal(false)}
                        >
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </button>
                        <h4 className="title title-up">{props.product.name}</h4>
                    </div>
                    <ModalBody>
                        <Container>
                            <ProductDetailCarousel product={props.product} />
                            <br></br>
                            <br></br>
                            <ProductDetailBody product={props.product} />
                        </Container>
                    </ModalBody>
                    <div className="modal-footer">
                        <Button className="btn-round"
                            size="lg"
                            target="_blank"
                            color="danger"
                            href="http://m.me/106323714562177">
                            Đặt mua
                        </Button>
                    </div>
                </Modal>
            </div>
        </>
    );
}
export default ProductDetailModal;
