/*eslint-disable*/
import React from "react";

import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

function DarkFooter(props) {
  let item = props.footerContent;
  return (
    <footer id="contact" className="footer" data-background-color="black">
      <Container>
        <Row>
          <Col md="8">
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink                           
                  href={item.page_image_1}
                  rel="noopener noreferrer"
                  target="_blank"               
                >
                  <i className="now-ui-icons location_pin"></i>&nbsp; {item.page_content_1}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href={`tel:${item.page_content_2}`} 
                >
                  <i className="now-ui-icons tech_mobile"></i>&nbsp; {item.page_content_2}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href={item.page_image_3}
                  target="_blank"                                
                >
                  <i className="now-ui-icons ui-1_email-85"></i>&nbsp; {item.page_content_3}
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="4">
            <div className="copyright" id="copyright">
              © Emachines Viet Nam {new Date().getFullYear()}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DarkFooter;
