import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

function ProductDetailBody(props) {

    let item = props.product;
    return (
        <>
            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Công nghệ CPU:
                </Col>
                <Col lg="7" md="7">
                    {item.cpu_brand} {item.cpu_type}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    Số nhân:
                </Col>
                <Col lg="7" md="7" >
                    {item.cpu_core}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Tốc độ CPU:
                </Col>
                <Col lg="7" md="7">
                    {item.cpu_speed}
                </Col>
            </Row>

            <Row className="section-tabs align-middle">
                <Col lg="5" md="5" className="font-weight-bold">
                    Tốc độ tối đa:
                </Col>
                <Col lg="7" md="7">
                    {item.turbo_boost_speed}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    RAM:
                </Col>
                <Col lg="7" md="7">
                    {item.memory}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    Loại RAM:
                </Col>
                <Col lg="7" md="7">
                    {item.memory_type}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Tốc độ Bus RAM:
                </Col>
                <Col lg="7" md="7">
                    {item.memory_speed}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    Khe RAM:
                </Col>
                <Col lg="7" md="7">
                    {item.memory_slot_total}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Khe RAM nâng cấp:
                </Col>
                <Col lg="7" md="7">
                    {item.memory_slot_available}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    Ổ cứng:
                </Col>
                <Col lg="7" md="7">
                    {item.ssd_interface} {item.ssd_configuration} {item.ssd_capacity}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Màn hình:
                </Col>
                <Col lg="7" md="7">
                    {item.screen_size}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    Độ phân giải:
                </Col>
                <Col lg="7" md="7">
                    {item.resolution}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Công nghệ màn hình:
                </Col>
                <Col lg="7" md="7">
                    {item.display_type}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    Camera trước:
                </Col>
                <Col lg="7" md="7">
                    {item.has_front_camera}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Độ phân giải camera trước:
                </Col>
                <Col lg="7" md="7">
                    {item.front_camera_resolution}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    Công nghệ camera:
                </Col>
                <Col lg="7" md="7">
                    {item.front_camera_feature}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Loa:
                </Col>
                <Col lg="7" md="7">
                    {item.speakers}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    Microphone:
                </Col>
                <Col lg="7" md="7">
                    {item.microphone}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    WLAN:
                </Col>
                <Col lg="7" md="7">
                    {item.wlan}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    Wireless:
                </Col>
                <Col lg="7" md="7">
                    {item.wireless}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Kết nối không dây:
                </Col>
                <Col lg="7" md="7">
                    {item.bluetooth}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    USB:
                </Col>
                <Col lg="7" md="7">
                    {item.usb}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Số cổng USB:
                </Col>
                <Col lg="7" md="7">
                    {item.number_of_usb_port}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    Số cổng HDMI:
                </Col>
                <Col lg="7" md="7">
                    {item.number_of_hdmi_port}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Số cổng VGA:
                </Col>
                <Col lg="7" md="7">
                    {item.number_of_vga_port}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    Số cổng Audio:
                </Col>
                <Col lg="7" md="7">
                    {item.number_of_audio_port}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Bàn phím:
                </Col>
                <Col lg="7" md="7">
                    {item.keybroad}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    PIN:
                </Col>
                <Col lg="7" md="7">
                    {item.battery}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Nguồn:
                </Col>
                <Col lg="7" md="7">
                    {item.power_supply}
                </Col>
            </Row>

            <Row className="section-tabs">
                <Col lg="5" md="5" className="font-weight-bold">
                    Kính thước:
                </Col>
                <Col lg="7" md="7">
                    Dài {item.width} - Rộng {item.depth} - Dày {item.height} - Nặng {item.weight}
                </Col>
            </Row>

            <Row>
                <Col lg="5" md="5" className="font-weight-bold">
                    Bảo hành:
                </Col>
                <Col lg="7" md="7">

                    {item.warranty}

                </Col>
            </Row>
        </>
    );
}

export default ProductDetailBody;
