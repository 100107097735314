import React, { useState } from "react";
import { useHistory } from 'react-router-dom'
import { Container, Row, Col } from "reactstrap";
import { Category } from "../../models";
import { DataStore } from '@aws-amplify/datastore';
import ProductDetail from "./product/ProductDetail.js";

function Product() {

  const [category, setCategory] = useState([]);
  const history = useHistory()

  async function fetchCategories(categoryId) {
    const content = await DataStore.query(Category, c => c.id("eq", categoryId));
    setCategory(content[0]);
  }

  React.useEffect(() => {
    let url = window.location.href;
    let lastSegment = url.split("/").pop();
    fetchCategories(lastSegment);
    window.scrollTo(0, 700);
    return history.listen((location) => {
      let url = window.location.href;
      let lastSegment = url.split("/").pop();
      fetchCategories(lastSegment);     
      window.scrollTo(0, 700);
    })
  }, [history]);

  return (
    <>
      <div className="section bg-light">
        <Container>
          <Row key={`Product-${category.id}`}>
            <Col lg="12" md="12" >
              <h2 className="title">{category.name}</h2>
            </Col>
            <Col lg="12" md="12" >
              <ProductDetail categoryId={category.id} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Product;