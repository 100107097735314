/* eslint-disable */
import React, { useState } from "react";
import { Card, CardImg, CardTitle, CardText, CardBody, Button, Container, Row, Col } from "reactstrap";
import { EqualHeight, EqualHeightElement } from 'react-equal-height';
import { Product } from "../../../models";
import { DataStore } from '@aws-amplify/datastore';

import ProductDetailModal from "./ProductDetailModal.js";

function ProductDetail(props) {

    const [products, setProducts] = useState([]);

    async function fetchProducts() {
        const data = await DataStore.query(Product, p => p.categoryID("eq", props.categoryId).is_sale("eq", false));
        setProducts(data);
    }

    React.useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <>
            {products.length > 0 ?
                <div className="section bg-light">
                    <Container>
                        <Row>
                            <EqualHeight>
                                {products.map(item => (
                                    <Col lg="4" key={`ProductDetail-${item.id}`}>
                                        <Card>
                                            <EqualHeightElement name="CardImage">
                                                <CardImg
                                                    alt="Product Image"
                                                    src={item.image_url[0]}
                                                    top
                                                    width="100%"
                                                    height="250px"
                                                />
                                            </EqualHeightElement>
                                            <EqualHeightElement name="CardBody">
                                                <CardBody>
                                                    <EqualHeightElement name="CardTitle">
                                                        <CardTitle
                                                            tag="h3"
                                                            className="font-weight-bold">
                                                            {item.name} {item.is_sale}
                                                        </CardTitle>
                                                    </EqualHeightElement>
                                                    <EqualHeightElement name="CardText1">
                                                        <CardText style={{ color: 'red' }}>
                                                            {item.price} VNĐ
                                                        </CardText>
                                                    </EqualHeightElement>
                                                    <EqualHeightElement name="CardText2">
                                                        <CardText>
                                                            {item.description}
                                                        </CardText>
                                                    </EqualHeightElement>
                                                    <EqualHeightElement name="CardButton">
                                                        < ProductDetailModal product={item} />
                                                    </EqualHeightElement>
                                                </CardBody>
                                            </EqualHeightElement>
                                        </Card>
                                    </Col>
                                ))}
                            </EqualHeight>
                        </Row>
                    </Container>
                </div>
                :
                <div className="section bg-light">
                    <Container>
                        <Row>
                            <p>
                                Không có sản phẩm!
                            </p>
                        </Row>
                    </Container>
                </div>
            }

        </>
    );
}
export default ProductDetail;
