import '../../assets/css/App.css';
import React from "react";

function Developing() {

  React.useEffect(() => {
		window.scrollTo(0, 700);
	}, []);

  return (
    <div className="App">
      <header className="App-header">        
        <p>
          Chức năng này đang được phát triển!
        </p>
      </header>
    </div>
  );
}

export default Developing;
