import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/scss/now-ui-kit.scss";

import App from './App.js';

import Amplify from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

ReactDOM.render(
	<AmplifyProvider>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</AmplifyProvider>,
	document.getElementById('root')
);