import React from "react";
import { Container, Row, Col } from "reactstrap";

function About(props) {
  let item = props.aboutContent;

  React.useEffect(() => {
    window.scrollTo(0, 700);
  }, []);

  return (
    <>
      <div className="section section-about-us">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Giới thiệu</h2>
              <h5 className="description" style={{ 'text-align': 'justify' }}>
                {item.page_content_1}
              </h5>
            </Col>
          </Row>
          <div className="separator separator-primary"></div>
          <div className="section-story-overview">
            <Row>
              <Col md="6">
                <div
                  className="image-container image-left"
                  style={{ backgroundImage: `url(${item.page_image_1})` }}
                >
                </div>
              </Col>
              <Col md="6">
                <div
                  className="image-container image-right"
                  style={{ backgroundImage: `url(${item.page_image_2})` }}
                >
                </div>
              </Col>
            </Row>

            <Row style={{ 'margin-top': '80px' }}>
              <Col md="6" style={{ 'margin-bottom': '80px' }}>
                <div
                  className="image-container"
                  style={{ backgroundImage: `url(${item.page_image_3})` }}
                ></div>
              </Col>
              <Col md="6" style={{ 'text-align': 'justify' }}>
                <h5>
                  {item.page_content_2}
                </h5>
                <h5>
                  {item.page_content_3}
                </h5>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}
export default About;

