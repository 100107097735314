import React from "react";
import { Route, Redirect } from "react-router-dom";

import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import CustomNavbar from "./components/CustomNavbar.js";

import Home from "./components/content/Home.js";
import About from "./components/content/About.js";
import Product from "./components/content/Product.js";
import Developing from "./components/content/Developing.js";
import Contact from "./components/content/Contact.js";

function Content(props) {

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <CustomNavbar categories={props.categories} />
      <div className="wrapper">
        <Header headerContent={props.headerContent} />
        <div className="main">
          <Route exact path="/">
            <Home categories={props.categories}
              aboutContent={props.aboutContent} />
          </Route>
          <Route path="/about">
            <About aboutContent={props.aboutContent} />
          </Route>
          <Route path="/product">
            <Product />
          </Route>
          <Route path="/developing">
            <Developing />
          </Route>
          <Route path="/contact">
            <Contact contactContent={props.contactContent}/>
          </Route>
          <Redirect to="/" />
        </div>
        <Footer footerContent={props.footerContent}/>
      </div>
    </>
  );
}

export default Content;