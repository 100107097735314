import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import {Link} from 'react-router-dom';

function HomeAbout(props) {

  function shortenContent(str, num) {    
    if (typeof str === 'undefined') {     
      return str;
    } else {
      if (str.length > num) {
        return str.slice(0, num) + '...';
      } else {
        return str;
      }
    }
  }

  let item = props.aboutContent;
  return (
    <>
      <div className="section section-nucleo-icons">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">GIỚI THIỆU</h2>
              <h5 className="description" style={{ 'text-align': 'justify' }}>
                {shortenContent(item.page_content_1, 380)}
              </h5>
              <Button
                className="btn-round mr-1"
                color="primary"
                tag={Link} to="/about"
                size="lg"
              >
                Tìm hiểu thêm
              </Button>
            </Col>
            <Col lg="6" md="12">
              <div className="nucleo-container">
                <img
                  alt="..."
                  src={item.page_image_1}
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default HomeAbout;