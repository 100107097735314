import React from "react";

import HomeAbout from "./home/HomeAbout.js";
import HomeProduct from "./home/HomeProduct.js";

function Home(props) {

  React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

  return (
    <>
      <HomeAbout aboutContent={props.aboutContent} />
      <HomeProduct categories={props.categories} />
    </>
  );
}

export default Home;