import React from "react";
import { Container, Row, Col } from "reactstrap";

function Contact(props) {
  let item = props.contactContent;

  React.useEffect(() => {
    window.scrollTo(0, 600);
  }, []);

  return (
    <div className="section section-about-us">
      <Container>
        <Row>
          <Col lg="12" md="12">
            <h2 className="title">E-Machines Việt Nam</h2>
          </Col>
        </Row>

        <Row>
          <Col lg="2" md="3" xs="4">
            <h5 className="description">
              <strong>Địa chỉ:</strong>
            </h5>
          </Col>
          <Col lg="10" md="9" xs="8">
            <h5 className="description">
              {item.page_content_1}
            </h5>
          </Col>
        </Row>

        <Row>
          <Col lg="2" md="3" xs="4">
            <h5 className="description">
              <strong>Số điện thoại:</strong>
            </h5>
          </Col>
          <Col lg="10" md="9" xs="8">
            <h5 className="description">
              {item.page_content_2}
            </h5>
          </Col>
        </Row>

        <Row>
          <Col lg="2" md="3" xs="4">

            <h5 className="description">
              <strong>Email:</strong>
            </h5>
          </Col>
          <Col lg="10" md="9" xs="8">

            <h5 className="description">
              {item.page_content_3}
            </h5>
          </Col>
        </Row>
      </Container>
    </div >
  );
}

export default Contact;

